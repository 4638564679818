import React, { Component } from "react";
import Spinner from "./Spinner"; // Include your Spinner component
import DonorInformation from "./DonorInformation";
import OrganizationDonorInformation from "./OrganizationDonorInformation";
import GiftInformation from "./GiftInformation";
import SoftCreditInformation from "./SoftCreditInformation";
import TributeInformation from "./TributeInformation";
import "./css/GiftBatches.css";
import LogoHeader from "./LogoHeader";
import Form from "react-bootstrap/Form";
import GiftBatchList from "./GiftBatchList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BatchService from "./BatchService"; // Import the BatchService class
import Comment from "./Comment";

const initialBatchState = {
  staffName: "",
  donorFirstName: "",
  donorLastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  province: "",
  postalCode: "",
  donorPhone: "",
  donorEmail: "",
  donorOrgName: "",
  donorType: "",
  giftCategory: "",
  customGiftCategory: "",
  orgContactFirstName: "",
  orgContactLastName: "",
  orgContactType: "",
  donorConstituentID: "",
  recognitionName: "",
  relationshipManager: "",
  anonymousPubliclyPerson: false,
  giftDate: "",
  glPostDate: "",
  giftType: "cash",
  giftSubType: "",
  receivedAmount: "",
  receiptAmount: "",
  appeal: "",
  package: "",
  fund: "",
  campaign: "",
  splitGift: "no",
  splitGiftComment: "",
  anonymousPubliclyGift: false,
  thankYouLetterType: "email",
  receiptType: "email",
  giftCode: "",
  softCreditFirstName: "",
  softCreditLastName: "",
  softCreditConstituentID: "",
  honoureeFirstName: "",
  honoureeLastName: "",
  honoureeConstituentID: "",
  acknowledgeeFirstName: "",
  acknowledgeeLastName: "",
  acknowledgeeContactInfo: "",
  tributeMessage: "",
  sponsorship: "",
  comments: "",
  isOpportunity: "no",
  isPledge: "no",
  opportunityURL: "",
  pledgeURL: ""
};

class GiftBatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      giftBatches: [],
      newBatch: initialBatchState,
      error: null,
      isLoading: false,
      screenWidth: window.innerWidth,
      appealOptions: [],
      campaignOptions: [],
      fundOptions: [],
      packageOptions: [],
      donorOptions: [],
      staffOptions: [],
      editedItem: null,
      category: "all",
      categories: [
        { name: "Pending", value: "pending" },
        { name: "Approved", value: "approve" },
        { name: "Disapproved", value: "disapprove" },
        { name: "Batched, Not Yet Receipted", value: "batched" },
        { name: "Receipted", value: "receipted" },
        { name: "All", value: "all" },
      ],
      giftCategoryOptions: [
        { name: "Check", value: "check" },
        { name: "EFT", value: "eft" },
        { name: "Other", value: "other" },
      ],
    };
  }

  async fetchData() {
    console.log("Fetching DATA!!!");
    fetch(`${process.env.REACT_APP_API_URL}/gaia-options`)
      .then((response) => response.json())
      .then(
        (data) => {
          console.log(data);

          this.setState({
            donorOptions: data.donor_options,
            staffOptions: data.staff_options,
            appealOptions: data.appeal_options,
            campaignOptions: data.campaign_options,
            fundOptions: data.fund_options,
            packageOptions: data.package_options,
          });
        },
        () => {
          console.log("SCREAM");
          console.log(this.state);
        }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  async fetchBatches() {
    try {
      this.setState({ isLoading: true });
      const batchesData = await BatchService.fetchBatches();
      this.setState({
        giftBatches: batchesData,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      this.setState({
        error: error.message,
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    // Fetch batches when the component mounts
    this.fetchData();
    this.fetchBatches();
  }

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

  updateExpandRow = (e, gift_ID, type) => {
    e.preventDefault();

    if (this.state.expandedRowID === gift_ID) {
      this.setState({
        expandedRowID: null,
      });

      return;
    }

    // Find the item with matching receiptID
    const foundItem = this.state.giftBatches.find(
      (item) => item.ID === gift_ID
    );

    console.log(foundItem);

    if (foundItem) {
      this.setState({
        expandedRowID: gift_ID,
        editedItem: foundItem,
        expandedRowType: type,
      });
    }
  };

  updateItem = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { editedItem } = this.state;

    fetch(`${process.env.REACT_APP_API_URL}/batching`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedItem),
    })
      .then((response) => {
        if (response.ok) {
          this.fetchBatches();
          toast.success("Gift batch updated!");
          this.setState({ expandedRowID: null });
        } else {
          toast.error("Error updating gift batch!");
        }
      })
      .catch((error) => {
        toast.error("Error updating gift batch:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleItemUpdate = (e) => {
    const { id, value } = e.target;
    const updatedNewItem = { ...this.state.editedItem, [id]: value };
    this.setState({ editedItem: updatedNewItem });
  };

  addBatch = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { newBatch, giftBatches } = this.state;

    try {
      // Make a POST request to the API
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/batching`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newBatch), // Convert the batch data to JSON
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add batch");
      }

      this.setState(
        {
          isLoading: false,
          error: null,
        },
        () => {
          this.fetchBatches();
        }
      );
    } catch (error) {
      // Handle any errors that occur during the fetch
      this.setState({
        error: error.message,
        isLoading: false,
      });
    }
  };

  handleInputChange = (fieldName, value) => {
    console.log("=======================");
    console.log(fieldName);
    console.log(value);

    this.setState((prevState) => ({
      newBatch: {
        ...prevState.newBatch,
        [fieldName]: value,
      },
    }));
  };

  handleEditorChange = (content) => {
    this.setState((prevState) => ({
      newBatch: {
        ...prevState.newBatch,
        tributeMessage: content,
      },
    }));
  };

  handleCommentsEditorChange = (content) => {
    this.setState((prevState) => ({
      newBatch: {
        ...prevState.newBatch,
        comments: content,
      },
    }));
  };

  render() {
    const {
      giftBatches,
      newBatch,
      error,
      isLoading,
      screenWidth,
      donorOptions,
      staffOptions,
      appealOptions,
      campaignOptions,
      fundOptions,
      packageOptions,
    } = this.state;

    return (
      <>
        <LogoHeader />
        {/* Your existing JSX structure */}
        <main>
          <section className="container stylization maincont">
            {/* Gift Batch Form */}
            <h1 className="main-ttl">
              <span>Add Donation Gift Form</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.addBatch} className="login">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="staffName">
                      Staff Name <span className="required">*</span>
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="staffName"
                        as="select"
                        value={newBatch.staffName}
                        onChange={(e) =>
                          this.handleInputChange("staffName", e.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose a staff member &#x2015;
                        </option>
                        {staffOptions.map((donor, index) => (
                          <>
                            <option key={donor.id} value={donor.option_name}>
                              {donor.option_name}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="donorType">
                      Donor Type <span className="required">*</span>
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="donorType"
                        as="select"
                        value={newBatch.donorType}
                        onChange={(e) =>
                          this.handleInputChange("donorType", e.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an donor type &#x2015;
                        </option>
                        {donorOptions.map((donor, index) => (
                          <>
                            <option key={donor.id} value={donor.option_name}>
                              {donor.option_name}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="giftCategory">
                      Gift Category <span className="required">*</span>
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="giftCategory"
                        as="select"
                        value={newBatch.giftCategory}
                        onChange={(e) =>
                          this.handleInputChange("giftCategory", e.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an gift category &#x2015;
                        </option>
                        {this.state.giftCategoryOptions.map((gift, index) => (
                          <>
                            <option key={gift.value} value={gift.value}>
                              {gift.name}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  {newBatch.giftCategory === "other" && (
                    <>
                      <p>
                        <label htmlFor="customGiftCategory">
                          Custom Gift Category{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          id="customGiftCategory"
                          name="Custom Gift Category"
                          value={newBatch.customGiftCategory}
                          onChange={(e) =>
                            this.handleInputChange(
                              "customGiftCategory",
                              e.target.value
                            )
                          }
                        />
                      </p>
                    </>
                  )}

                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="isPledge">
                      Is this a pledge?
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="isPledge"
                        as="select"
                        value={newBatch.isPledge}
                        onChange={(e) =>
                          this.handleInputChange("isPledge", e.target.value)
                        }
                      >
                        <option key="yes" value="yes">
                          Yes
                        </option>
                        <option key="no" value="no">
                          No
                        </option>
                      </Form.Control>
                    </span>
                  </p>

                  {newBatch.isPledge === "yes" && (
                    <>
                      <p>
                        <label htmlFor="pledgeURL">
                          Pledge URL{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          id="pledgeURL"
                          name="pledgeURL"
                          value={newBatch.pledgeURL}
                          onChange={(e) =>
                            this.handleInputChange(
                              "pledgeURL",
                              e.target.value
                            )
                          }
                        />
                      </p>
                    </>
                  )}

                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="isOpportunity">
                      Is there an associated opportunity?
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="isOpportunity"
                        as="select"
                        value={newBatch.isOpportunity}
                        onChange={(e) =>
                          this.handleInputChange("isOpportunity", e.target.value)
                        }
                      >
                        <option key="yes" value="yes">
                          Yes
                        </option>
                        <option key="no" value="no">
                          No
                        </option>
                      </Form.Control>
                    </span>
                  </p>


                  {newBatch.isOpportunity === "yes" && (
                    <>
                      <p>
                        <label htmlFor="opportunityURL">
                          Opportunity URL{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          id="opportunityURL"
                          name="opportunityURL"
                          value={newBatch.opportunityURL}
                          onChange={(e) =>
                            this.handleInputChange(
                              "opportunityURL",
                              e.target.value
                            )
                          }
                        />
                      </p>
                    </>
                  )}


                  {newBatch.donorType === "Individual" && (
                    <DonorInformation
                      handleInputChange={this.handleInputChange}
                      newBatch={newBatch}
                      staffOptions={staffOptions}
                    />
                  )}
                  {newBatch.donorType === "Business" && (
                    <OrganizationDonorInformation
                      handleInputChange={this.handleInputChange}
                      newBatch={newBatch}
                      staffOptions={staffOptions}
                    />
                  )}

                  <GiftInformation
                    handleInputChange={this.handleInputChange}
                    newBatch={newBatch}
                    appealOptions={appealOptions}
                    campaignOptions={campaignOptions}
                    fundOptions={fundOptions}
                    packageOptions={packageOptions}
                  />

                  <SoftCreditInformation
                    handleInputChange={this.handleInputChange}
                    newBatch={newBatch}
                  />

                  <TributeInformation
                    handleInputChange={this.handleInputChange}
                    newBatch={newBatch}
                    handleEditorChange={this.handleEditorChange}
                  />

                  <Comment
                    handleInputChange={this.handleInputChange}
                    newBatch={newBatch}
                    handleEditorChange={this.handleCommentsEditorChange}
                  />

                  {/* Repeat similar blocks for other fields */}
                  {/* Submit Button */}
                  <p className="auth-submit">
                    <input type="submit" value="Submit" disabled={isLoading} />
                  </p>
                </form>
                {/* Display error message if any */}
                {error && (
                  <div className="err404">
                    <p className="err404-search">{error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col"></div>
            </div>
          </section>

          {/* List of Gift Batches */}
          {this.state.giftBatches.length > 0 && (
            <GiftBatchList
              giftBatches={this.state.giftBatches}
              isLoading={this.state.isLoading}
              updateExpandRow={this.updateExpandRow}
              expandedRowID={this.state.expandedRowID}
              expandedRowType={this.state.expandedRowType}
              toast={toast}
              editedItem={this.state.editedItem}
              handleItemUpdate={this.handleItemUpdate}
              updateItem={this.updateItem}
              categories={this.state.categories}
              category={this.state.category}
              handleOptionChange={this.handleOptionChange}
            />
          )}

          <ToastContainer />
          {/* Display spinner when loading */}
          {isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default GiftBatches;