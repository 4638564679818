import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class OrganizationDonorInformation extends Component {
  render() {
    const { newBatch, handleInputChange, staffOptions } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Organization Information</h5>
          <p>

            <label htmlFor="donorOrgName">Name <span className="required">*</span></label>
            <input
              type="text"
              id="donorOrgName"
              name="Organization Name"
              value={newBatch.donorOrgName}
              onChange={(e) =>
                handleInputChange("donorOrgName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="addressLine1">
              Address Line 1 <span className="required">*</span>
            </label>
            <input
              type="text"
              id="addressLine1"
              name="Address Line 1"
              value={newBatch.addressLine1}
              onChange={(e) =>
                handleInputChange("addressLine1", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="addressLine2">Address Line 2</label>
            <input
              type="text"
              id="addressLine2"
              name="Address Line 2"
              value={newBatch.addressLine2}
              onChange={(e) =>
                handleInputChange("addressLine2", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="city">
              City <span className="required">*</span>
            </label>
            <input
              type="text"
              id="city"
              name="City"
              value={newBatch.city}
              onChange={(e) =>
                handleInputChange("city", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="province">
              Province <span className="required">*</span>
            </label>
            <input
              type="text"
              id="province"
              name="Province"
              value={newBatch.province}
              onChange={(e) =>
                handleInputChange("province", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="postalCode">
              Postal Code <span className="required">*</span>
            </label>
            <input
              type="text"
              id="postalCode"
              name="Postal Code"
              value={newBatch.postalCode}
              onChange={(e) =>
                handleInputChange("postalCode", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorEmail">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              id="donorEmail"
              name="Donor Email"
              value={newBatch.donorEmail}
              onChange={(e) =>
                handleInputChange("donorEmail", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorPhone">
              Phone <span className="required">*</span>
            </label>
            <input
              type="text"
              id="donorPhone"
              name="Donor Phone"
              value={newBatch.donorPhone}
              onChange={(e) =>
                handleInputChange("donorPhone", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="orgContactFirstName">Contact First Name</label>
            <input
              type="text"
              id="orgContactFirstName"
              name="Organization Contact First Name"
              value={newBatch.orgContactFirstName}
              onChange={(e) =>
                handleInputChange("orgContactFirstName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="orgContactLastName">Contact Last Name</label>
            <input
              type="text"
              id="orgContactLastName"
              name="Organization Contact Last Name"
              value={newBatch.orgContactLastName}
              onChange={(e) =>
                handleInputChange("orgContactLastName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="orgContactType">Contact Type</label>
            <input
              type="text"
              id="orgContactType"
              name="Organization Contact Type"
              value={newBatch.orgContactType}
              onChange={(e) =>
                handleInputChange("orgContactType", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="donorConstituentID">Constituent ID</label>
            <input
              type="text"
              id="donorConstituentID"
              name="Donor Constituent ID"
              value={newBatch.donorConstituentID}
              onChange={(e) =>
                handleInputChange("donorConstituentID", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="recognitionName">Recognition Name</label>
            <input
              type="text"
              id="recognitionName"
              name="Recognition Name"
              value={newBatch.recognitionName}
              onChange={(e) =>
                handleInputChange("recognitionName", e.target.value)
              }
            />
          </p>
          <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="relationshipManager">
                    Relationship Manager <span className="required">*</span>
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="relationshipManager"
                        as="select"
                        value={newBatch.relationshipManager}
                        onChange={(e) =>
                          handleInputChange("relationshipManager", e.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an Relationship Manager &#x2015;
                        </option>
                        {staffOptions.map((donor, index) => (
                          <>
                            <option key={donor.id} value={donor.option_name}>
                              {donor.option_name}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

        </section>
      </div>
    );
  }
}

export default OrganizationDonorInformation;
