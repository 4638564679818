import React, { Component } from "react";
import UpdateGiftForm from "./UpdateGiftForm"; // Assuming UpdateGiftForm is in a separate file
import AdditionalNotes from "./AdditionalNotes";
import * as XLSX from "xlsx";
import Form from "react-bootstrap/Form";

class GiftBatchList extends Component {
  exportData = (e) => {
    e.preventDefault();

    const results = this.props.giftBatches.map((batch) => ({
      ...batch,
      CreateDate: this.convertUTCtoPST(batch.CreateDate),
    }));

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert attendees data to worksheet
    const ws = XLSX.utils.json_to_sheet(results);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "GiftBatches");

    // Generate a unique filename for the Excel file
    const filename = `all_gift_batches_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);

    const date = new Date();
    const options = {
      timeZone: "America/Los_Angeles", // Set time zone to PST
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const timestamp = date.toLocaleString("en-US", options);
    const message = `Nathania Gaia Batch export triggered at ${timestamp}.`;

    fetch(`${process.env.REACT_APP_API_URL}/notify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: message }),
    });
  };

  convertUTCtoPST = (timestampString) => {
    const date = new Date(timestampString);

    if (isNaN(date.getTime())) {
      return "Invalid Time";
    }

    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;

    const pstTime = new Date(date - offsetMilliseconds);

    const pstTimeString = pstTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });

    return pstTimeString;
  };

  render() {
    const {
      isLoading,
      title,
      admin,
      notesText,
      updateNotes,
      addNote,
      updateStatus,
      toast,
      editedItem,
      handleItemUpdate,
      updateItem,
      categories,
      category,
      handleOptionChange,
    } = this.props;

    return (
      <>
        <section className="container stylization maincont">
          <div className="auth-wrap">
            <div className="auth-col">
              <form className="form-validate">
                <p className="contactform-field contactform-text">
                  <label className="contactform-label">Status</label>
                  <span className="contactform-input">
                    <Form.Control
                      id="amount"
                      as="select"
                      value={category}
                      onChange={handleOptionChange}
                    >
                      <option
                        value=""
                        disabled
                        hidden
                        style={{ color: "#999" }}
                      >
                        &#x2015; Choose an option &#x2015;
                      </option>
                      {categories.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                  </span>
                </p>
                <p>
                  <button onClick={this.exportData}>
                    Export ALL Data As Excel
                  </button>
                </p>
              </form>
            </div>
          </div>

          <h1 className="main-ttl">
            <span>{title ? title : "List of Gift Batches"}</span>
          </h1>
          <div className="cart-items-wrap">
            <table className="cart-items">
              <thead>
                <tr>
                  <td className="cart-ttl">ID</td>
                  <td className="cart-ttl">Staff Name</td>
                  <td className="cart-ttl">Received Amount</td>
                  <td className="cart-ttl">Create Date</td>
                  <td className="cart-ttl">Status</td>
                  <td className="cart-ttl">Notes</td>
                  {admin && (
                    <>
                      <td className="cart-del">&nbsp;</td>
                      <td className="cart-del">&nbsp;</td>
                      <td className="cart-del">&nbsp;</td>
                    </>
                  )}

                  <td className="cart-del">&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                {this.props.giftBatches


                .filter(item => {
                  const giftStatus = item.GiftStatus?.toLowerCase();
                  if (category === "all") {
                    return true;
                  }

                  if (category === "receipted") {
                    if (giftStatus.includes("receipt")) {
                      return true;
                    }
                  }

                  if (category === "batched") {
                    if (giftStatus.includes("batch") && !giftStatus.includes("receipt")) {
                      return true;
                    }
                  }

                  if (item.GiftStatus?.toLowerCase() === category.toLowerCase()) {
                    return true;
                  }
                  return false;
                })
                  .sort((a, b) => parseInt(b.ID) - parseInt(a.ID))
                  .map((batch, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{batch.ID}</td>
                        <td>{batch.staffName}</td>
                        <td>{batch.receivedAmount}</td>
                        <td>{this.convertUTCtoPST(batch.CreateDate)}</td>
                        <td>{batch.GiftStatus}</td>
                        <td dangerouslySetInnerHTML={{ __html: batch.Note }} />
                        {admin && (
                          <>
                            <td className="cart-del">
                              <a
                                className="cart-add"
                                href="/"
                                onClick={(e) => {
                                  updateStatus(e, batch, "Approve");
                                }}
                                role="button"
                              >
                                {"\u00A0"}
                              </a>
                            </td>

                            <td className="cart-del">
                              <a
                                className="cart-remove"
                                href="/"
                                onClick={(e) => {
                                  updateStatus(e, batch, "Disapprove");
                                }}
                                role="button"
                              >
                                {"\u00A0"}
                              </a>
                            </td>
                            <td className="cart-del">
                              <a
                                className="cart-notes"
                                href="/"
                                onClick={(e) => {
                                  this.props.updateExpandRow(
                                    e,
                                    batch.ID,
                                    "Note"
                                  );
                                }}
                                role="button"
                              >
                                {"\u00A0"}
                              </a>
                            </td>
                          </>
                        )}

                        <td className="cart-del">
                          <a
                            className="cart-edit"
                            href="/"
                            onClick={(e) => {
                              this.props.updateExpandRow(e, batch.ID, "Gift");
                            }}
                            role="button"
                          >
                            {"\u00A0"}
                          </a>
                        </td>
                      </tr>
                      {this.props.expandedRowID === batch.ID && (
                        <tr className="notes-row">
                          <td colSpan="5">
                            {this.props.expandedRowType === "Note" && (
                              <>
                                <AdditionalNotes
                                  text={notesText}
                                  handleChange={updateNotes}
                                  addNote={addNote}
                                  batch={batch}
                                />
                              </>
                            )}

                            {this.props.expandedRowType === "Gift" && (
                              <UpdateGiftForm
                                isLoading={isLoading}
                                toast={toast}
                                editedItem={editedItem}
                                handleItemUpdate={handleItemUpdate}
                                updateItem={updateItem}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </>
    );
  }
}

export default GiftBatchList;