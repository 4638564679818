import React, { Component } from "react";
import "./css/TaskManager.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoHeader from "./LogoHeader2";
import Handshake from "./img/handshake.png";
import Spinner from "./Spinner";
import { confirmAlert } from 'react-confirm-alert';
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css';

class TaskManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      options: [],
      isLoading: false,
      optionName: "",
      newOptionName: "",
      newOptionID: null
    };
  }

  handleOptionNameChange = (e) => {
    this.setState({ newOptionName: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {
      const { newOptionName } = this.state;

      if (!newOptionName.trim()) {
        this.setState({ error: `Please fill in all required fields for ${this.state.optionName}`, isLoading: false }, () => {
          toast.error(`Please fill in all required fields for ${this.state.optionName}!`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      } else {
        try {
          const optionData = {
            option_name: newOptionName,
          };

          const response = await fetch(`${process.env.REACT_APP_API_URL}/${this.state.optionType}-options`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(optionData),
          });

          if (response.ok) {
            toast.success(`${newOptionName} added successfully!`);

            this.setState({
              optionName: "",
              isLoading: false,
              error: ""
            }, () => {
              this.fetchOptions();
            });
          } else {
            throw new Error('API request failed');
          }
        } catch (error) {
          console.error(`Error adding ${newOptionName}:`, error);
          this.setState({ isLoading: false, error: "Something went wrong, try again!" });
        }
      }
    });
  };

  fetchOptions = () => {
    fetch(`${process.env.REACT_APP_API_URL}/${this.state.optionType}-options`)
      .then((response) => response.json())
      .then((data) => {
        const dataList = data[`${this.state.optionType}_options`];
        console.log(dataList);

        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            this.setState({ options: dataList });
          }
        }
      })
      .catch((error) => console.error(`Error fetching ${this.state.optionType}-options:`, error));
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const optionType = urlParams.get('type');
    const optionName = urlParams.get('name');
    console.log(optionType);
    console.log(optionName);
    
    this.setState({ isLoading: true, optionType, optionName }, () => {
      this.fetchOptions();
      this.setState({ isLoading: false });
    });
  }

  handleEditSubmit = () => {
    const userInput = {
        ID: this.state.newOptionID,
        option_name: this.state.newOptionName
    };

    if (this.state.newOptionName) {
      fetch(`${process.env.REACT_APP_API_URL}/${this.state.optionType}-options`, {
        method: 'PUT',
        body: JSON.stringify(userInput),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(() => {
          toast.success(`${this.state.optionName} name updated to ${this.state.newOptionName}`);
          this.fetchOptions();
        })
        .catch((error) => {
          toast.error(`Failed to update ${this.state.optionName} name`);
        });
    } else {
      toast.warn('No new name provided');
    }
  }

  handleEdit = (event, id, option) => {
    event.preventDefault();
  
    this.setState({ newOptionName: option, newOptionID: id });
  
    confirmAlert({
      title: '',
      message: `Please enter the new ${this.state.optionName} name:`,
      buttons: [
        {
          label: 'Submit',
          className: 'green-button',
          onClick: () => {
            this.handleEditSubmit();
          },
        },
        {
          label: 'Cancel',
          className: 'red-button',
          onClick: () => {},
        },
      ],
      childrenElement: (props) => {
        const myThis = this;

        return (
          <input
            type="text"
            value={myThis.myValue}
            autoFocus
            onChange={(e) => { 
              myThis.setState({ newOptionName: e.target.value }, () => {
                myThis.myValue = e.target.value;
              });
            }}
          />
        );
      }
    });
  };
  

  handleDelete = (event, id) => {
    event.preventDefault();
  
    confirmAlert({
      title: 'Confirm Deletion',
      message: `Are you sure you want to delete this?`,
      buttons: [
        {
          label: 'Yes',
          className: 'green-button',
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/${this.state.optionType}-options?option_id=${id}`;
  
            try {
              await axios.delete(apiUrl);
  
              toast.success(`${this.state.optionName} Deleted!`);
  
              this.fetchOptions();
            } catch (error) {
              console.error(`Error deleting ${this.state.optionName.toLowerCase()}`, error);
            }
          }
        },
        {
          label: 'No',
          className: 'red-button',
          onClick: () => console.log(`Deletion of ${this.state.optionName.toLowerCase()} canceled by user`)
        }
      ]
    });
  };

  render() {
    return (
      <>
      <LogoHeader />
      <main>
        <section className="container stylization maincont">
          <h1 className="main-ttl">
            <span>Add a {this.state.optionName}</span>
          </h1>

          <div className="auth-wrap">
            <div className="auth-col">
              <form onSubmit={this.handleSubmit} className="form-validate">
                <p>
                  <label htmlFor="category">
                    {this.state.optionName} Name
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={this.state.newOptionName}
                    onChange={this.handleOptionNameChange}
                  />
                </p>
  
                <p className="auth-submit">
                  <input type="submit" value={`Create a ${this.state.optionName}`} />
                </p>
              </form>
              {this.state.error && (
                <div className="err404">
                  <p className="err404-search">{this.state.error}</p>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="container stylization maincont">
          <h1 className="main-ttl">
            <span>Current {this.state.optionName} Options</span>
          </h1>
          {this.state.options.length > 0 && (
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">{this.state.optionName} Name</td>
                    <td className="cart-del"></td>
                    <td className="cart-del"></td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.options
                    .slice()
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDelete(e, result.id);
                            }}
                          >
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">{result.option_name}</td>
                        <td className="cart-del">
                          <a
                            className="cart-edit"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleEdit(e, result.id, result.option_name);
                            }}
                            role="button"
                          >
                            {"\u00A0"}
                          </a>
                        </td>
                        <td className="cart-del">
                          <a
                            className="cart-remove"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleDelete(e, result.id);
                            }}
                            role="button"
                          >
                            {"\u00A0"}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </section>

        {this.state.isLoading && (<Spinner />)}
      </main>
      <ToastContainer />
      </>
    );
  }
}

export default TaskManager;