import React, { Component } from "react";
import "./css/UpdateGiftForm.css";

class UpdateGiftForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }



  render() {
    const {
      editedItem,
      attendeeError,
      isLoading,
      handleItemUpdate,
      updateItem
    } = this.props;

    const chunkArrayBySizes = (array, sizes) => {
      const results = [];
      let startIndex = 0;
      sizes.forEach(size => {
        const endIndex = startIndex + size;
        results.push(array.slice(startIndex, endIndex));
        startIndex = endIndex;
      });
      return results;
    };

    const renderFormFields = (fields) => (
      fields.map(([key, value]) => (
        <p key={key}>
          <label htmlFor={`Update_${key}`}>{key.replace(/_/g, ' ')}:</label>
          {
            (key === "CreateDate" || key === "ID" || key === 'Row_ID') ? (
              <input
                type="text"
                id={`${key}`}
                name={`${key}`}
                value={value}
                readOnly
              />
            ) : (
              <input
                type="text"
                id={`${key}`}
                name={`${key}`}
                value={value}
                onChange={handleItemUpdate}
              />
            )
          }
        </p>
      ))
    );
    

    const formFields = editedItem ? Object.entries(editedItem) : [];
    let chunkSizes = [5, 17,15, 3];

    const totalSize = formFields.length;
    const hasGiftCategory = editedItem && 'giftCategory' in editedItem && 'isPledge'  in editedItem && 'isOpportunity' in editedItem;

    if (hasGiftCategory) {
      chunkSizes = [10, 17,15, 3];
    }
    const specifiedSizes = chunkSizes.reduce((a, b) => a + b, 0);
    const remainingSize = totalSize - specifiedSizes;
    
    if (remainingSize > 0) {
      chunkSizes.push(remainingSize);
    }
    

    const chunkedFields = chunkArrayBySizes(formFields, chunkSizes);

    return (
      <div className="auth-col">
        <form onSubmit={updateItem}  className="login">
        {chunkedFields.map((fields, index) => (
      <div key={index} className="well">
        {renderFormFields(fields)}
      </div>
    ))}
          <p className="auth-submit">
                <input type="submit" value="Update Item" disabled={isLoading} />
              </p>
        </form>

        {attendeeError && (
          <div className="err404">
            <p className="err404-search">{attendeeError}</p>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateGiftForm;