import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Add Quill styles

class TributeInformation extends Component {
  render() {
    const editorStyle = {
      height: "100px", // Adjust this value to increase the height
    };

    const { newBatch, handleInputChange, handleEditorChange } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Tribute Information</h5>
          <p>
            <label htmlFor="honoureeFirstName">Honouree First Name</label>
            <input
              type="text"
              id="honoureeFirstName"
              name="Honouree First Name"
              value={newBatch.honoureeFirstName}
              onChange={(e) =>
                handleInputChange("honoureeFirstName", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="honoureeLastName">Honouree Last Name</label>
            <input
              type="text"
              id="honoureeLastName"
              name="Honouree Last Name"
              value={newBatch.honoureeLastName}
              onChange={(e) =>
                handleInputChange("honoureeLastName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="honoureeConstituentID">
              Honouree Constituent ID
            </label>
            <input
              type="text"
              id="honoureeConstituentID"
              name="Honouree Constituent ID"
              value={newBatch.honoureeConstituentID}
              onChange={(e) =>
                handleInputChange("honoureeConstituentID", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="acknowledgeeFirstName">Ack First Name</label>
            <input
              type="text"
              id="acknowledgeeFirstName"
              name="Acknowledgee First Name"
              value={newBatch.acknowledgeeFirstName}
              onChange={(e) =>
                handleInputChange("acknowledgeeFirstName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="acknowledgeeLastName">Ack Last Name</label>
            <input
              type="text"
              id="acknowledgeeLastName"
              name="Acknowledgee Last Name"
              value={newBatch.acknowledgeeLastName}
              onChange={(e) =>
                handleInputChange("acknowledgeeLastName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="acknowledgeeContactInfo">Ack Contact Info</label>
            <input
              type="text"
              id="acknowledgeeContactInfo"
              name="Acknowledgee Contact Info"
              value={newBatch.acknowledgeeContactInfo}
              onChange={(e) =>
                handleInputChange("acknowledgeeContactInfo", e.target.value)
              }
            />
          </p>
          <p style={{paddingBottom: "50px"}}>
            <label htmlFor="tributeMessage" style={{marginBottom: "20px"}}>Tribute Message</label>
            <span className="contactform-input">
            <ReactQuill
              style={editorStyle}
              value={newBatch.tributeMessage}
              onChange={handleEditorChange}
            />
          </span>
          </p>
        </section>
      </div>
    );
  }
}

export default TributeInformation;
