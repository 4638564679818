import React, { Component } from "react";

class SoftCreditInformation extends Component {
  render() {
    const { newBatch, handleInputChange } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Soft Credit Information</h5>
          <p>
            <label htmlFor="softCreditFirstName">Soft Credit First Name</label>
            <input
              type="text"
              id="softCreditFirstName"
              name="Soft Credit First Name"
              value={newBatch.softCreditFirstName}
              onChange={(e) =>
                handleInputChange("softCreditFirstName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="softCreditLastName">Soft Credit Last Name</label>
            <input
              type="text"
              id="softCreditLastName"
              name="Soft Credit Last Name"
              value={newBatch.softCreditLastName}
              onChange={(e) =>
                handleInputChange("softCreditLastName", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="softCreditConstituentID">
              Soft Credit Constituent ID
            </label>
            <input
              type="text"
              id="softCreditConstituentID"
              name="Soft Credit Constituent ID"
              value={newBatch.softCreditConstituentID}
              onChange={(e) =>
                handleInputChange(
                  "softCreditConstituentID",
                  e.target.value
                )
              }
            />
          </p>
        </section>
      </div>
    );
  }
}

export default SoftCreditInformation;
