import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Add Quill styles

class Comment extends Component {
  render() {
    const editorStyle = {
      height: "100px", // Adjust this value to increase the height
    };

    const { newBatch, handleInputChange, handleEditorChange } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Additional Information</h5>

          <p style={{paddingBottom: "50px"}}>
            <label htmlFor="comments" style={{marginBottom: "20px"}}>Comments</label>
            <span className="contactform-input">
            <ReactQuill
              style={editorStyle}
              value={newBatch.comments}
              onChange={handleEditorChange}
            />
          </span>
          </p>
        </section>
      </div>
    );
  }
}

export default Comment;
