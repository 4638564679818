import axios from "axios";

class BatchService {
  static async fetchBatches() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/batching`);
      if (!response.ok) {
        throw new Error("Failed to fetch batches");
      }

      const batchesData = await response.json();

      const fieldsInOrder = [
        "ID",
        "CreateDate",
        "GiftStatus",
        "staffName",
        "donorType",
        "giftCategory",
        "giftCustomCategory",
        "isOpportunity",
        "opportunityURL",
        "isPledge",
        "pledgeURL",
        
        "donorFirstName",
        "donorLastName",
        "donorOrgName",
        "orgContactFirstName",
        "orgContactLastName",
        "orgContactType",
        "addressLine1",
        "addressLine2",
        "city",
        "province",
        "postalCode",
        "donorEmail",
        "donorPhone",
        "donorConstituentID",
        "anonymousPubliclyPerson",
        "recognitionName",
        "relationshipManager",

        "giftDate",
        "giftType",
        "giftSubType",
        "giftCode",
        "receivedAmount",
        "receiptAmount",
        "appeal",
        "fund",
        "campaign",
        "package",
        "splitGift",
        "splitGiftComment",
        "anonymousPubliclyGift",
        "sponsorship",
        "glPostDate",

        "softCreditFirstName",
        "softCreditLastName",
        "softCreditConstituentID",

        "honoureeFirstName",
        "honoureeLastName",
        "honoureeConstituentID",



        "acknowledgeeFirstName",
        "acknowledgeeLastName",
        "acknowledgeeContactInfo",
        "thankYouLetterType",
        "tributeMessage"
      ];
      
      // Map the fetched data to the desired format with reordered properties
      const mappedData = batchesData.map(batch => {
        const mappedBatch = {};
        // Map the fields in the desired order
        fieldsInOrder.forEach(field => {
          if (batch.hasOwnProperty(field)) {
            if (batch.hasOwnProperty(field)) {
              mappedBatch[field] = batch[field];
            }
          }
        });
        return mappedBatch;
      });

      // Append any remaining fields that were not mapped
      const remainingFields = batchesData.map(batch => {
        const remainingBatch = {};
        for (const key in batch) {
          if (!fieldsInOrder.includes(key)) {
            remainingBatch[key] = batch[key];
          }
        }
        return remainingBatch;
      });
      
      // Merge the mapped data and the remaining fields
      const finalData = mappedData.map((batch, index) => Object.assign(batch, remainingFields[index]));
      

      return finalData;
    } catch (error) {
      throw new Error("Failed to fetch batches: " + error.message);
    }
  }

  static async updateBatch(batch) {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/batching`, batch);
      if (response.status === 200) {
        return true;
      } else {
        throw new Error("Failed to update batch");
      }
    } catch (error) {
      throw new Error("Failed to update batch: " + error.message);
    }
  }

  // Add more methods as needed
}

export default BatchService;
