import React from "react";
import logo from "./img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

function LogoHeader2() {

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          <li>
              <a href="/admin">
              <FontAwesomeIcon icon={faPeopleGroup} />&nbsp;
                <span className="shop-menu-ttl">Admin</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=campaign&name=Campaign">
              <FontAwesomeIcon icon={faChartBar} />&nbsp;
                <span className="shop-menu-ttl">Campaign Options</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=appeal&name=Appeal">
              <FontAwesomeIcon icon={faPrint} />&nbsp;
                <span className="shop-menu-ttl">Appeal Options</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=fund&name=Fund">
              <FontAwesomeIcon icon={faTicket} />&nbsp;
                <span className="shop-menu-ttl">Fund Options</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=package&name=package">
              <FontAwesomeIcon icon={faDisplay} />&nbsp;
                <span className="shop-menu-ttl">Package Options</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=donor&name=Donor%20Type">
              <FontAwesomeIcon icon={faWineBottle} />&nbsp;
                <span className="shop-menu-ttl">Donor Options</span>
              </a>
            </li>
            <li>
              <a href="/manager?type=staff&name=Staff">
              <FontAwesomeIcon icon={faReceipt} />&nbsp;
                <span className="shop-menu-ttl">Staff Options</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader2;