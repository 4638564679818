import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles

class GiftInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { newBatch, handleInputChange, appealOptions, campaignOptions, fundOptions, packageOptions } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Gift Information</h5>
          <p>
            <label htmlFor="giftDate">
              Gift Date <span className="required">*</span>
            </label>

            <DatePicker
              selected={newBatch.giftDate ? new Date(newBatch.giftDate) : null}
              onChange={(value) =>
                handleInputChange("giftDate", value.toISOString().substr(0, 10))
              }
              dateFormat="yyyy-MM-dd" // Customize date format if needed
            />
          </p>

          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="giftType">
              Gift Type <span className="required">*</span>
            </label>
            <span className="contactform-input">
              <Form.Control
                id="giftType"
                as="select"
                value={newBatch.giftType}
                onChange={(e) => handleInputChange("giftType", e.target.value)}
              >
                <option key="cash" value="cash">
                  Cash
                </option>
                <option key="pledge" value="pledge">
                Pledge
                </option>
                <option key="stock" value="stock">
                  Stock/Property
                </option>
                <option key="giftInKind" value="giftInKind">
                  Gift In Kind
                </option>
                <option key="other" value="other">
                  Other
                </option>
                <option key="recurring" value="recurring">
                  Recurring Gift
                </option>
              </Form.Control>
            </span>
          </p>

          {newBatch.giftType === "other" && <>
          <p>
            <label htmlFor="otherGiftType">
              Other Gift Type <span className="required">*</span>
            </label>
            <input
              type="text"
              id="otherGiftType"
              name="Other Gift Type"
              value={newBatch.otherGiftType}
              onChange={(e) =>
                handleInputChange("otherGiftType", e.target.value)
              }
            />
          </p>
          </>}
          <p>
            <label htmlFor="receivedAmount">
              Donated Amount <span className="required">*</span>
            </label>
            <input
              type="text"
              id="receivedAmount"
              name="Received Amount"
              value={newBatch.receivedAmount}
              onChange={(e) =>
                handleInputChange("receivedAmount", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="receiptAmount">
              Receipt Amount <span className="required">*</span>
            </label>
            <input
              type="text"
              id="receiptAmount"
              name="Receipt Amount"
              value={newBatch.receiptAmount}
              onChange={(e) =>
                handleInputChange("receiptAmount", e.target.value)
              }
            />
          </p>
          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="appeal">
              Appeal <span className="required">*</span>
            </label>
            <span className="contactform-input">
              <Form.Control
                id="appeal"
                as="select"
                value={newBatch.appeal}
                onChange={(e) => handleInputChange("appeal", e.target.value)}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose an appeal &#x2015;
                </option>
                {appealOptions.map((appeal) => (
                  <>
                    <option key={appeal.id} value={appeal.option_name}>
                      {appeal.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </span>
          </p>

          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="fund">
              Fund <span className="required">*</span>
            </label>
            <span className="contactform-input">
              <Form.Control
                id="fund"
                as="select"
                value={newBatch.fund}
                onChange={(e) => handleInputChange("fund", e.target.value)}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a fund &#x2015;
                </option>
                {fundOptions.map((fund) => (
                  <>
                    <option key={fund.id} value={fund.option_name}>
                      {fund.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </span>
          </p>

          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="campaign">
              Campaign <span className="required">*</span>
            </label>
            <span className="contactform-input">
              <Form.Control
                id="campaign"
                as="select"
                value={newBatch.campaign}
                onChange={(e) => handleInputChange("campaign", e.target.value)}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a campaign &#x2015;
                </option>
                {campaignOptions.map((campaign) => (
                  <>
                    <option key={campaign.id} value={campaign.option_name}>
                      {campaign.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </span>
          </p>

          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="package">
              Package <span className="required">*</span>
            </label>
            <span className="contactform-input">
              <Form.Control
                id="package"
                as="select"
                value={newBatch.package}
                onChange={(e) => handleInputChange("package", e.target.value)}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a package &#x2015;
                </option>
                {packageOptions.map((package_) => (
                  <>
                    <option key={package_.id} value={package_.option_name}>
                      {package_.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </span>
          </p>



          <p className="contactform-field contactform-text">
            <label className="contactform-label" htmlFor="splitGift">
              Split Gift
            </label>
            <span className="contactform-input">
              <Form.Control
                id="splitGift"
                as="select"
                value={newBatch.splitGift}
                onChange={(e) => handleInputChange("splitGift", e.target.value)}
              >
                <option key="yes" value="yes">
                  Yes
                </option>
                <option key="no" value="no">
                  No
                </option>
              </Form.Control>
            </span>
          </p>

          {newBatch.splitGift === "yes" && (
            <>
              <p className="contactform-field contactform-text">
                <label className="contactform-label" htmlFor="otherFund">
                  Other Fund <span className="required">*</span>
                </label>
                <span className="contactform-input">
                  <Form.Control
                    id="otherFund"
                    as="select"
                    value={newBatch.otherFund}
                    onChange={(e) => handleInputChange("otherFund", e.target.value)}
                  >
                    <option value="" disabled hidden style={{ color: "#999" }}>
                      &#x2015; Choose a fund &#x2015;
                    </option>
                    {fundOptions.map((otherFund, index) => (
                      <>
                        <option key={otherFund.id} value={otherFund.option_name}>
                          {otherFund.option_name}
                        </option>
                      </>
                    ))}
                  </Form.Control>
                </span>
              </p>
            </>
          )}

          {newBatch.otherFund !== "" && newBatch.fund !== "" && newBatch.splitGift === "yes" && <p>This gift will be split between funds {newBatch.fund} and {newBatch.otherFund}.</p>}

          <p className="contactform-field contactform-text">
            <label
              className="contactform-label"
              htmlFor="anonymousPubliclyGift"
            >
              Anonymous Publicly Gift
            </label>
            <span className="contactform-input">
              <Form.Control
                id="anonymousPubliclyGift"
                as="select"
                value={newBatch.anonymousPubliclyGift}
                onChange={(e) =>
                  handleInputChange("anonymousPubliclyGift", e.target.value)
                }
              >
                <option key="true" value={true}>
                  Yes
                </option>
                <option key="false" value={false}>
                  No
                </option>
              </Form.Control>
            </span>
          </p>

          <p className="contactform-field contactform-text">
            <label
              className="contactform-label"
              htmlFor="sponsorship"
            >
              Sponsorship
            </label>
            <span className="contactform-input">
              <Form.Control
                id="sponsorship"
                as="select"
                value={newBatch.sponsorship}
                onChange={(e) =>
                  handleInputChange("sponsorship", e.target.value)
                }
              >
                 <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Pick an option &#x2015;
                </option>
                <option key="true" value={true}>
                  Yes
                </option>
                <option key="false" value={false}>
                  No
                </option>
              </Form.Control>
            </span>
          </p>
        </section>
      </div>
    );
  }
}

export default GiftInformation;
