import "./css/App.css";
import Test from "./Test";
import NotFound from "./NotFound";
import GiftBatches from "./GiftBatches";
import BulkGiftBatches from "./BulkGiftBatches";
import Admin from "./Admin";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import TaskManager from "./TaskManager";
import Auth from "./Auth";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <Routes>
        <Route path="/" element={<BulkGiftBatches />} />
        <Route path="/test" element={<Test />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/manager" element={<TaskManager />} />
        <Route path="/bulk" element={<BulkGiftBatches />} />
        <Route path="/batching" element={<GiftBatches />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;