import React, { Component } from "react";
import Spinner from "./Spinner"; // Include your Spinner component
import "./css/GiftBatches.css";
import LogoHeader from "./LogoHeader";
import axios from "axios";
import { read, utils } from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GiftBatchList from "./GiftBatchList";
import BatchService from "./BatchService"; // Import the BatchService class


class BulkGiftBatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      giftBatches: [],
      error: null,
      isLoading: false,
      screenWidth: window.innerWidth,
      expandedRowID: null,
      expandedRowType: null,
      notesText: "",
      editedItem: null,
      category: "all",
      categories: [
        { name: "Pending", value: "pending" },
        { name: "Approved", value: "approve" },
        { name: "Disapproved", value: "disapprove" },
        { name: "Batched, Not Yet Receipted", value: "batched" },
        { name: "Receipted", value: "receipted" },
        { name: "All", value: "all" }
      ]
    };
  }

  async fetchBatches() {
    try {
      this.setState({ isLoading: true });
      const batchesData = await BatchService.fetchBatches();
      this.setState({
        giftBatches: batchesData,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      this.setState({
        error: error.message,
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    // Fetch batches when the component mounts
    this.fetchBatches();
  }

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

  updateExpandRow = (e, gift_ID, type) => {
    e.preventDefault();

    if (this.state.expandedRowID === gift_ID) {
      this.setState({
        expandedRowID: null
      });

      return;
    }

    // Find the item with matching receiptID
    const foundItem = this.state.giftBatches.find(
      (item) => item.ID === gift_ID
    );

    console.log(foundItem);

    if (foundItem) {
      this.setState({
        expandedRowID: gift_ID,
        editedItem: foundItem,
        expandedRowType: type,
      });
    }
  };


  updateItem = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { editedItem } = this.state;

    fetch(`${process.env.REACT_APP_API_URL}/batching`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedItem),
    })
      .then((response) => {
        if (response.ok) {
          this.fetchBatches();
          toast.success("Gift batch updated!");
          this.setState({expandedRowID: null});
        } else {
          toast.error("Error updating gift batch!");
        }
      })
      .catch((error) => {
        toast.error("Error updating gift batch:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleItemUpdate = (e) => {
    const { id, value } = e.target;
    const updatedNewItem = { ...this.state.editedItem, [id]: value };
    this.setState({ editedItem: updatedNewItem });
  };

  updateNotes = (value) => {
    this.setState({ notesText: value });
  };

  addNote = async (e, receipt_ID) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const records = [{ receiptID: receipt_ID, note: this.state.notesText }];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/record-mail-notes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ records }),
        }
      );

      if (response.ok) {
        toast.success(`Note added for Receipt ${receipt_ID}.`);
        this.fetchReceiptData();
      } else {
        throw new Error("Failed to add note.");
      }
    } catch (error) {
      toast.error("Error add note:", error.message);
      // Handle errors or display error messages to the user
    } finally {
      this.setState({ isLoading: false, expandedRowID: null, notesText: "" });
    }
  };

  parseSpreadsheet = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const binaryString = event.target.result;
        const workbook = read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to parse
        const worksheet = workbook.Sheets[sheetName];
        const data = utils.sheet_to_json(worksheet, { header: 1 });

        // Extract headers from the first row
        const headers = data[0];

        // Parse each subsequent row into a dictionary
        const parsedData = data.slice(1).map((row, index) => {
          const record = {};
          headers.forEach((header, columnIndex) => {
            record[header] = row[columnIndex];
          });
          record["Row_ID"] = `${index + 1}`; // Add Row_ID for each record starting at 1
          return record;
        });

        console.log(parsedData);

        // POST the parsed data to an API endpoint
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/batching/bulk`,
            { records: parsedData }
          );
          console.log("Parsed data sent to server:", response.data);
        } catch (error) {
          console.error("Error sending parsed data to server:", error);
        }

        resolve(data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(file);
    });
  };

  handleFileChange = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const file = event.target.files[0]; // Retrieve the selected file from the event

    // Pass the file to the parseSpreadsheet function
    try {
      await this.parseSpreadsheet(file);
      await this.fetchBatches();
      this.setState({ file, isLoading: false });
    } catch (error) {
      console.error("Error parsing spreadsheet:", error);
      this.setState({ error: "Error parsing spreadsheet.", isLoading: false });
    }
  };

  render() {
    const { error, isLoading } = this.state;

    return (
      <>
        <LogoHeader />
        {/* Your existing JSX structure */}
        <main>
          <section className="container stylization maincont">
            {/* Gift Batch Form */}
            <h1 className="main-ttl">
              <span>Bulk Gift Batching Processing</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.addBatch} className="login">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="donorType">
                      Upload XLSX <span className="required">*</span>
                    </label>
                    <input
                      type="file"
                      id="fileUpload"
                      name="fileUpload"
                      onChange={this.handleFileChange}
                    />
                  </p>
                </form>
                {/* Display error message if any */}
                {error && (
                  <div className="err404">
                    <p className="err404-search">{error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col"></div>
            </div>
          </section>

          {/* List of Gift Batches */}
          {this.state.giftBatches.length > 0 && (
              <GiftBatchList
              giftBatches={this.state.giftBatches}
              isLoading={this.state.isLoading}
              updateExpandRow={this.updateExpandRow}
              expandedRowID={this.state.expandedRowID}
              expandedRowType={this.state.expandedRowType}
              toast={toast}
              editedItem={this.state.editedItem}
              handleItemUpdate={this.handleItemUpdate}
              updateItem={this.updateItem}
              categories={this.state.categories}
              category={this.state.category}
              handleOptionChange={this.handleOptionChange}
            />
          )}
          <ToastContainer />
          {/* Display spinner when loading */}
          {isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default BulkGiftBatches;