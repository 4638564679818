import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class DonorInformation extends Component {
  render() {
    const { newBatch, handleInputChange, staffOptions } = this.props;

    return (
      <div className="section-container">
        <section className="stylization">
          <h5>Donor Information</h5>
          <p>
            <label htmlFor="donorFirstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="donorFirstName"
              name="Donor First Name"
              value={newBatch.donorFirstName}
              onChange={(e) =>
                handleInputChange("donorFirstName", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorLastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="donorLastName"
              name="Donor Last Name"
              value={newBatch.donorLastName}
              onChange={(e) =>
                handleInputChange("donorLastName", e.target.value)
              }
            />
          </p>


          <p>
            <label htmlFor="addressLine1">
              Address Line 1 <span className="required">*</span>
            </label>
            <input
              type="text"
              id="addressLine1"
              name="Address Line 1"
              value={newBatch.addressLine1}
              onChange={(e) =>
                handleInputChange("addressLine1", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="addressLine2">Address Line 2</label>
            <input
              type="text"
              id="addressLine2"
              name="Address Line 2"
              value={newBatch.addressLine2}
              onChange={(e) =>
                handleInputChange("addressLine2", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="city">
              City <span className="required">*</span>
            </label>
            <input
              type="text"
              id="city"
              name="City"
              value={newBatch.city}
              onChange={(e) =>
                handleInputChange("city", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="province">
              Province <span className="required">*</span>
            </label>
            <input
              type="text"
              id="province"
              name="Province"
              value={newBatch.province}
              onChange={(e) =>
                handleInputChange("province", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="postalCode">
              Postal Code <span className="required">*</span>
            </label>
            <input
              type="text"
              id="postalCode"
              name="Postal Code"
              value={newBatch.postalCode}
              onChange={(e) =>
                handleInputChange("postalCode", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorEmail">
              Email
            </label>
            <input
              type="email"
              id="donorEmail"
              name="Donor Email"
              value={newBatch.donorEmail}
              onChange={(e) =>
                handleInputChange("donorEmail", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorPhone">Phone</label>
            <input
              type="text"
              id="donorPhone"
              name="Donor Phone"
              value={newBatch.donorPhone}
              onChange={(e) =>
                handleInputChange("donorPhone", e.target.value)
              }
            />
          </p>

          <p>
            <label htmlFor="donorConstituentID">Constituent ID</label>
            <input
              type="text"
              id="donorConstituentID"
              name="Donor Constituent ID"
              value={newBatch.donorConstituentID}
              onChange={(e) =>
                handleInputChange("donorConstituentID", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="recognitionName">Recognition Name</label>
            <input
              type="text"
              id="recognitionName"
              name="Recognition Name"
              value={newBatch.recognitionName}
              onChange={(e) =>
                handleInputChange("recognitionName", e.target.value)
              }
            />
          </p>
          <p className="contactform-field contactform-text">
                    <label className="contactform-label" htmlFor="relationshipManager">
                    Relationship Manager <span className="required">*</span>
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="relationshipManager"
                        as="select"
                        value={newBatch.relationshipManager}
                        onChange={(e) =>
                          handleInputChange("relationshipManager", e.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an Relationship Manager &#x2015;
                        </option>
                        {staffOptions.map((donor, index) => (
                          <>
                            <option key={donor.id} value={donor.option_name}>
                              {donor.option_name}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </span>
                  </p>


          <p className="contactform-field contactform-text">
            <label
              className="contactform-label"
              htmlFor="anonymousPubliclyPerson"
            >
              Anonymous Publicly Person
            </label>
            <span className="contactform-input">
              <Form.Control
                id="amount"
                as="select"
                value={newBatch.anonymousPubliclyPerson}
                onChange={(e) =>
                  handleInputChange("anonymousPubliclyPerson", e.target.value)
                }
              >
                <option key="true" value={true}>
                  Yes
                </option>
                <option key="false" value={false}>
                  No
                </option>
              </Form.Control>
            </span>
          </p>

        </section>
      </div>
    );
  }
}

export default DonorInformation;
